import { CALL_API } from '../apiMiddleware'

export const FETCH_TESTIMONIALS = 'FETCH_TESTIMONIALS'
export const FETCH_TESTIMONIALS_SUCCESS = 'FETCH_TESTIMONIALS_SUCCESS'
export const FETCH_TESTIMONIALS_FAILURE = 'FETCH_TESTIMONIALS_FAILURE'

export const REQUEST_ADD_TESTIMONIAL = 'REQUEST_ADD_TESTIMONIAL'
export const REQUEST_ADD_TESTIMONIAL_SUCCESS = 'REQUEST_ADD_TESTIMONIAL_SUCCESS'
export const REQUEST_ADD_TESTIMONIAL_FAILURE = 'REQUEST_ADD_TESTIMONIAL_FAILURE'

export const REQUEST_DELETE_TESTIMONIAL = 'REQUEST_DELETE_TESTIMONIAL'
export const REQUEST_DELETE_TESTIMONIAL_SUCCESS = 'REQUEST_DELETE_TESTIMONIAL_SUCCESS'
export const REQUEST_DELETE_TESTIMONIAL_FAILURE = 'REQUEST_DELETE_TESTIMONIAL_FAILURE'

export const fetchTestimonials = () => ({
  [CALL_API]: {
    types: [FETCH_TESTIMONIALS, FETCH_TESTIMONIALS_SUCCESS, FETCH_TESTIMONIALS_FAILURE],
    endpoint: '/api/testimonials',
    options: {
      method: 'GET'
    }
  }
})

export const addNewTestimonial = (testimonial) => {
  const formData = new FormData()
  Object.keys(testimonial).forEach(key => {
    formData.append(key, testimonial[key])
  })
  return {
    [CALL_API]: {
      types: [REQUEST_ADD_TESTIMONIAL, REQUEST_ADD_TESTIMONIAL_SUCCESS, REQUEST_ADD_TESTIMONIAL_FAILURE],
      endpoint: '/api/testimonials',
      options: {
        method: 'POST',
        body: formData
      }
    }
  }
}

export const deleteTestimonial = (id) => ({
  [CALL_API]: {
    types: [REQUEST_DELETE_TESTIMONIAL, REQUEST_DELETE_TESTIMONIAL_SUCCESS, REQUEST_DELETE_TESTIMONIAL_FAILURE],
    endpoint: `/api/testimonials/${id}`,
    options: {
      method: 'DELETE'
    }
  }
})