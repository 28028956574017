import React, { Component, lazy, Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faUserTie,
  faImages,
  faEdit,
  faListAlt,
  faTrashAlt,
  faSignOutAlt,
  faQuestionCircle,
  faBell,
  faDownload,
  faSearchDollar,
  faComments,
  faHome,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import moment from "moment";
import { toast } from "react-toastify";
import { withCookies } from "react-cookie";

import Layout from "./components/Layout";
import LoadingState from "./components/LoadingState";

import ProtectedRoute from "./HOCs/ProtectedRoute";
import "./App.scss";

const Agents = lazy(() => import("./views/Agents"));
const Gallery = lazy(() => import("./views/Gallery"));
const Inquiries = lazy(() => import("./views/Inquiries"));
const HotListing = lazy(() => import("./views/HotListing"));
const InActive = lazy(() => import("./views/InActive"));
const SearchListing = lazy(() => import("./views/SearchListing"));
const AddEvent = lazy(() => import("./views/AddEvent"));
const EditEvent = lazy(() => import("./views/AddEvent"));
const AddAgent = lazy(() => import("./views/AddAgent"));
const Login = lazy(() => import("./views/Login"));
const Covers = lazy(() => import("./views/Covers"));
const Route404 = lazy(() => import("./views/Route404"));
const AddCover = lazy(() => import("./views/AddCover"));
const Wanted = lazy(() => import("./views/Wanted"));
const UpdateWantedInquiry = lazy(() => import("./views/Wanted/UpdateWantedInquiry"));
const Testimonials = lazy(() => import("./views/Testimonials"));
const AddTestimonial = lazy(() => import("./views/AddTestimonial"));

const GroupPhoto = lazy(() => import("./views/GroupPhoto"));
const AddGroupPhoto = lazy(() => import("./views/AddGroupPhoto"));
const UpdateProjectVideos = lazy(() => import("./views/UpdateProjectVideos"));

const InstantValuation = lazy(() => import("./views/InstantValuation"));
const Franchise = lazy(() => import("./views/Franchise"));
const GetInTouch = lazy(() => import("./views/getInTouch"));


const Dashboard = lazy(() => import("./views/Dashboard"));

library.add(
  faBars,
  faUserTie,
  faImages,
  faEdit,
  faListAlt,
  faTrashAlt,
  faSignOutAlt,
  faQuestionCircle,
  faBell,
  faDownload,
  faSearchDollar,
  faComments,
  faHome,
  faUsers
);

class App extends Component {
  state = {
    authTimer: null,
  };
  componentDidMount() {
    const { cookies } = this.props;
    this.setTimer(cookies);
  }

  componentWillReceiveProps(nextProps) {
    const { authTimer } = this.state;
    if (!authTimer) {
      const { cookies } = nextProps;
      this.setTimer(cookies);
    }
  }

  setTimer = (cookies) => {
    const authExp = cookies.get("auth-expiry");
    if (authExp) {
      const timeTillTokenExpiry = authExp - moment(Date.now()).unix();
      const authTimer = setTimeout(this.logout, timeTillTokenExpiry * 1000);
      this.setState({ authTimer });
    }
  };

  logout = () => {
    const { history } = this.props;
    toast.info("Pleae login again to continue!");
    clearInterval(this.state.authTimer);
    this.setState({ authTimer: null });
    history.push("/login");
  };

  componentWillUnmount() {
    clearInterval(this.state.authTimer);
  }

  render() {
    return (
      <Layout>
        <Suspense fallback={<LoadingState />}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <ProtectedRoute exact path="/" component={Dashboard} />
            <ProtectedRoute exact path="/advisors" component={Agents} />
            <ProtectedRoute exact path="/franchise" component={Franchise} />
            <ProtectedRoute exact path="/getInTouch" component={GetInTouch} />
            <ProtectedRoute exact path="/gallery" component={Gallery} />
            <ProtectedRoute exact path="/covers" component={Covers} />
            <ProtectedRoute exact path="/inquiries" component={Inquiries} />
            <ProtectedRoute exact path="/HotListing" component={HotListing} />
            <ProtectedRoute exact path="/InActive" component={InActive} />
            <ProtectedRoute exact path="/HotListing/Add Fresh Listing" component={SearchListing}
            />
            <ProtectedRoute
              exact
              path="/testimonials/add"
              component={AddTestimonial}
            />
            <ProtectedRoute exact path="/group-photo" component={GroupPhoto} />
            <ProtectedRoute
              exact
              path="/group-photo/Edit-group-photo"
              component={AddGroupPhoto}
            />
            <ProtectedRoute
              exact
              path="/group-photo/add-group-photo"
              component={AddGroupPhoto}
            />
            <ProtectedRoute path="/gallery/add-event" component={AddEvent} />
            <ProtectedRoute path="/gallery/edit-event" component={EditEvent} />
            <ProtectedRoute path="/advisors/add-agent" component={AddAgent} />
            <ProtectedRoute path="/agents/Edit-agent" component={AddAgent} />
            <ProtectedRoute path="/covers/add-cover" component={AddCover} />
            <ProtectedRoute path="/wanted" component={Wanted} />
            <ProtectedRoute path="/wantedinquiry/:wantedId" component={UpdateWantedInquiry} />
            <ProtectedRoute path="/valuation" component={InstantValuation} />
            {/* <ProtectedRoute path="/valuation" component={SearchListing} /> */}

            <ProtectedRoute
              exact
              path="/testimonials"
              component={Testimonials}
            />
            <ProtectedRoute
              exact
              path="/testimonials"
              component={Testimonials}
            />
            <ProtectedRoute
              exact
              path="/testimonials"
              component={Testimonials}
            />
            <ProtectedRoute
              exact
              path="/project-videos"
              component={UpdateProjectVideos}
            />
            <Route component={Route404} />
          </Switch>
        </Suspense>
      </Layout>
    );
  }
}

export default withRouter(withCookies(App));
