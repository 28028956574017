import { combineReducers } from 'redux';
import moment from 'moment';
import * as ActionTypes from '../../actions';
import { SET_ORDER_BY, SET_LEAD_STATUS, SET_FILTER_DATE_RANGE, SET_CURRENT_PAGE, SET_PER_PAGE, SET_FOCUSED_INPUT } from '../../actions/wanted';


const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: [],
  pagination: {
    per_page: null,
    current_page: null,
    total_pages: null,
    total_records: null,
  },
}

const filtersInitialState = {
  orderBy: 'DESC',
  startDate: moment().startOf('year').subtract(20, 'years'),
  endDate: moment().endOf('day'),
  leadStatus:'',
  current_page:1,
  per_page:5,
  focusedInput: null
}

const queries = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_WANTED_QUERIES:
      return Object.assign({}, state, { isLoading: true });

    case ActionTypes.FETCH_WANTED_QUERIES_SUCCESS:
      return Object.assign({}, state, { isLoading: false, isDataAvailable: action.payload.data.length > 0,
         data: action.payload.data, pagination: action.payload.pagination, });

    case ActionTypes.FETCH_WANTED_QUERIES_FAILURE:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
}

const filters = (state = filtersInitialState, action) => {
  switch (action.type) {

    case SET_FILTER_DATE_RANGE: {
      const { payload: { startDate, endDate } } = action;
      return Object.assign({}, state, { startDate, endDate });
    }

    case SET_ORDER_BY:
      return Object.assign({}, state, { orderBy: action.payload });

    case SET_LEAD_STATUS:
      return Object.assign({}, state, { leadStatus: action.payload });

    case SET_CURRENT_PAGE:
      return Object.assign({}, state, { current_page: action.payload });

    case SET_PER_PAGE:
      return Object.assign({}, state, { per_page: action.payload });

    case SET_FOCUSED_INPUT:
      return Object.assign({}, state, { focusedInput: action.payload });


    default:
      return state;
  }
}

export const wanted = combineReducers({
  queries,
  filters
})