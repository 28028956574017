import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';

class ProtectedRoute extends Component {
  componentDidMount(){
    const { history, cookies } = this.props;
    // console.log("cookie", cookies.get('authorization'))
    if(!cookies.get('authorization')){
      // console.log("auth cookie is: ", cookies.get("authorization"))
      history.push('/login')
    }
  }

  componentWillReceiveProps(nextProps){
    if(!nextProps.cookies.get('authorization')){
      this.props.history.push('/login')
    }
  }

  render(){
    return(
      <Route {...this.props} />
    );
  }
}

export default withRouter(withCookies(ProtectedRoute));