// import moment from 'moment';
import { CALL_API } from '../apiMiddleware';

export const FETCH_WANTED_QUERIES = 'FETCH_WANTED_QUERIES';
export const FETCH_WANTED_QUERIES_SUCCESS = 'FETCH_WANTED_QUERIES_SUCCESS';
export const FETCH_WANTED_QUERIES_FAILURE = 'FETCH_WANTED_QUERIES_FAILURE';


export const SET_FILTER_DATE_RANGE = 'SET_FILTER_DATE_RANGE';
export const SET_FOCUSED_INPUT = 'SET_FOCUSED_INPUT';


export const SET_ORDER_BY = 'SET_ORDER_BY';
export const SET_LEAD_STATUS = 'SET_LEAD_STATUS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_PER_PAGE = 'SET_PER_PAGE';

export const setOrderBy = (ob) => ({
  type: SET_ORDER_BY,
  payload: ob
})

export const setLeadStatus = (status) => ({
  type: SET_LEAD_STATUS,
  payload: status
})

export const setCurrentPage = (currentPage) => ({
  type: SET_CURRENT_PAGE,
  payload: currentPage
})

export const setPerPage = (perPage,) => ({
  type: SET_PER_PAGE,
  payload: perPage
})

export const setWantedDaterangeFilter = ({ startDate, endDate }) => ({
  type: SET_FILTER_DATE_RANGE,
  payload: { startDate, endDate }
})

export const setFocusedInput = (focused) => ({
  type: SET_FOCUSED_INPUT,
  payload: focused
})

export const fetchWantedQueries = ({orderBy='DESC', startDate, endDate, leadStatus, per_page, current_page}) => ({
  [CALL_API]: {
    types: [FETCH_WANTED_QUERIES, FETCH_WANTED_QUERIES_SUCCESS, FETCH_WANTED_QUERIES_FAILURE],
    endpoint: `/api/wanted?per_page=${per_page}&current_page=${current_page}&order=${orderBy}&approved=${leadStatus ? leadStatus==='approved' ? 1 : 0 : null}&startDate=${startDate.unix()*1000}&endDate=${endDate.unix()*1000}`,
    options: {
      method: 'GET'
    }
  }
})