import { isUndefined } from 'lodash';
import { CALL_API } from '../apiMiddleware';
import { hotListing } from '../reducers/hotListing';

export const FETCH_INQUIRIES = 'FETCH_INQUIRIES';
export const FETCH_INQUIRIES_SUCCESS = 'FETCH_INQUIRIES_SUCCESS';
export const FETCH_INQUIRIES_FAILURE = 'FETCH_INQUIRIES_FAILURE';


export const SET_HOTLISTING_QUERIES_DATE_RANGE = 'SET_VALUATION_QUERIES_DATE_RANGE';
export const SET_HOTLISTING_QUERIES_ORDER = 'SET_HOTLISTING_QUERIES_ORDER';
export const SET_INACTIVE_QUERIES_ORDER = 'SET_INACTIVE_QUERIES_ORDER';
// export const SET_HOTLISTING_QUERIES_ORDERBY = 'SET_HOTLISTING_QUERIES_ORDERBY';
// export const SET_INACTIVE_QUERIES_ORDERBY = 'SET_INACTIVE_QUERIES_ORDERBY';

export const SET_HOTLISTING_FOCUSED_INPUT = 'SET_VALUATION_FOCUSED_INPUT';
export const SET_HOTLISTING_STATUS ='SET_HOTLISTING_STATUS'


export const FETCH_HOTLISTING = 'FETCH_HOTLISTING';
export const FETCH_HOTLISTING_SUCCESS = 'FETCH_HOTLISTING_SUCCESS';
export const FETCH_HOTLISTING_FAILURE = 'FETCH_HOTLISTING_FAILURE';
export const FETCH_HOTLISTING_INACTIVE= 'FETCH_HOTLISTING_INACTIVE';

export const DELETE_HOTLISTING = 'DELETE_HOTLISTING';
export const DELETE_HOTLISTING_SUCCESS = 'DELETE_HOTLISTING_SUCCESS';
export const DELETE_HOTLISTING_FAILURE = 'DELETE_HOTLISTING_FAILURE';

export const DEACTIVATE_HOTLISTING  = 'DEACTIVATE_HOTLISTING';
export const DEACTIVATE_HOTLISTING_SUCCESS  = 'DEACTIVATE_HOTLISTING_SUCCESS';;
export const DEACTIVATE_HOTLISTING_FAILURE  = 'DEACTIVATE_HOTLISTING_FAILURE';

export const ACTIVATE_HOTLISTING  = 'ACTIVATE_HOTLISTING';
export const ACTIVATE_HOTLISTING_SUCCESS  = 'ACTIVATE_HOTLISTING_SUCCESS';;
export const ACTIVATE_HOTLISTING_FAILURE  = 'ACTIVATE_HOTLISTING_FAILURE';
// export const SET_HOTLISTING_STATUS = 'SET_HOTLISTING_STATUS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_PER_PAGE = 'SET_PER_PAGE';


export const setHotlistingQueriesOrder = (order) => ({
  type: SET_HOTLISTING_QUERIES_ORDER,
  payload:  order
})
export const setInactiveQueriesOrder = (order) => ({
  type: SET_INACTIVE_QUERIES_ORDER,
  payload:  order
})
// export const setHotlistingQueriesOrderBy = (orderBy) => ({
//   type: SET_HOTLISTING_QUERIES_ORDERBY,
//   payload:  orderBy
// })
// export const setInactiveQueriesOrderBy = (orderBy) => ({
//   type: SET_INACTIVE_QUERIES_ORDERBY,
//   payload:  orderBy
// })
export const setHotListingStatus = (status) => ({
  type: SET_HOTLISTING_STATUS,
  payload: status
})
export const setCurrentPage = (currentPage) => ({
  type: SET_CURRENT_PAGE,
  payload: currentPage
})

export const setPerPage = (perPage) => ({
  type: SET_PER_PAGE,
  payload: perPage
})

export const setInquiryQueriesDateRange = ({ startDate, endDate }) => ({
  type: SET_HOTLISTING_QUERIES_DATE_RANGE,
  payload: { startDate, endDate }
})

export const setInquiryQueriesOrder = (order) => ({
  type: SET_HOTLISTING_QUERIES_ORDER,
  payload: order
})

export const setInquiryFilterDateFocus = (focused) => ({
  type: SET_HOTLISTING_FOCUSED_INPUT,
  payload: focused
})
// console.log("idhr status?",status)


export const gethotlisting = ({orderBy='updatedAt', order = 'DESC',  per_page=5, current_page=1,status ,startDate, endDate }) => ({

 
   [CALL_API]: {
    types: [FETCH_HOTLISTING, FETCH_HOTLISTING_SUCCESS, FETCH_HOTLISTING_FAILURE],
    endpoint: `/api/hotListing?orderBy=${orderBy}&order=${order}&per_page=${per_page}&current_page=${current_page}&status=${status}`,
    options: {
      method: 'GET'
    }
  }
})
// ?per_page=${per_page}&current_page=${current_page}&status=0
// startDate=${startDate.unix()*1000}&endDate=${endDate.unix()*1000}
export const deletehotlisting = (id) => ({
  [CALL_API]: {
    types: [DELETE_HOTLISTING, DELETE_HOTLISTING_SUCCESS, DELETE_HOTLISTING_FAILURE],
    endpoint: '/api/hotListing/delete',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id })
    }
  }
})
export const deactivatehotlisting = (id) => ({
  [CALL_API]: {
    types: [DEACTIVATE_HOTLISTING, DEACTIVATE_HOTLISTING_SUCCESS, DEACTIVATE_HOTLISTING_FAILURE],
    endpoint: '/api/hotListing/deactivate',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( {id,status:0} )
    }
  }
})
export const activatehotlisting = (id) => ({
  [CALL_API]: {
    types: [ACTIVATE_HOTLISTING, ACTIVATE_HOTLISTING_SUCCESS, ACTIVATE_HOTLISTING_FAILURE],
    endpoint: '/api/hotListing/activate',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify( {id,status:1} )
    }
  }
})
export default hotListing;
