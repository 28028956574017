import isEmpty from "lodash/isEmpty";
import { combineReducers } from "redux";
import moment from "moment";
import * as ActionTypes from "../../actions";

import {
  REQUEST_VALUATION_STATUS_CHANGE,
  REQUEST_VALUATION_STATUS_CHANGE_FAILURE,
  REQUEST_VALUATION_STATUS_CHANGE_SUCCESS,
  SET_VALUATION_FOCUSED_INPUT,
  SET_VALUATION_QUERIES_DATE_RANGE,
  SET_VALUATION_QUERIES_ORDER,
} from "../../actions/instantValuation";

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: [],
};

const queries = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_VALUATION_QUERIES:
      return { ...state, isLoading: true };

    case REQUEST_VALUATION_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data.filter((item) => item.id !== action.payload.id),
          action.payload,
        ].sort((a, b) =>
          action.initialPayload.attributes.orderBy === "ASC"
            ? moment(a.createdAt).unix() - moment(b.createdAt).unix()
            : moment(b.createdAt).unix() - moment(a.createdAt).unix()
        ),
      };

    case REQUEST_VALUATION_STATUS_CHANGE_FAILURE:
      return { ...state, isLoading: false };

    case ActionTypes.FETCH_VALUATION_QUERIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDataAvailable: !isEmpty(action.payload),
        data: action.payload,
      };

    case ActionTypes.FETCH_VALUATION_QUERIES_FAILURE:
      return { ...state, ...initialState };

    default:
      return state;
  }
};

const updateInitialState = {
  isUpdating: false,
  id: null,
  status: null,
};

const queryUpdating = (state = updateInitialState, action) => {
  switch (action.type) {
    case REQUEST_VALUATION_STATUS_CHANGE:
      return {
        ...state,
        isUpdating: true,
        id: action.payload.id,
        status: action.payload.attributes.status,
      };

    case REQUEST_VALUATION_STATUS_CHANGE_SUCCESS:
    case REQUEST_VALUATION_STATUS_CHANGE_FAILURE:
      return { ...state, isUpdating: false };

    default:
      return state;
  }
};

const filtersInitialState = {
  orderBy: "DESC",
  startDate: moment().startOf("day").subtract(30, "days"),
  endDate: moment().endOf("day"),
  focusedInput: null,
};

const filters = (state = filtersInitialState, action) => {
  switch (action.type) {
    case SET_VALUATION_FOCUSED_INPUT:
      return { ...state, focusedInput: action.payload };

    case SET_VALUATION_QUERIES_ORDER:
      return { ...state, orderBy: action.payload };

    case SET_VALUATION_QUERIES_DATE_RANGE: {
      const {
        payload: { startDate, endDate },
      } = action;
      return { ...state, startDate, endDate };
    }

    default:
      return state;
  }
};

export default combineReducers({
  queries,
  queryUpdating,
  filters,
});
