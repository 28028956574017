import {CALL_API} from '../apiMiddleware';

export const FETCH_GROUP_PHOTO = 'FETCH_GROUP_PHOTO'
export const FETCH_GROUP_PHOTO_SUCCESS = 'FETCH_GROUP_PHOTO_SUCCESS'
export const FETCH_GROUP_PHOTO_FAILURE = 'FETCH_GROUP_PHOTO_FAILURE'

export const REQUEST_ADD_GROUP_PHOTO = 'REQUEST_ADD_GROUP_PHOTO'
export const ADD_GROUP_PHOTO_SUCCESS = 'ADD_GROUP_PHOTO_SUCCESS'
export const ADD_GROUP_PHOTO_FAILURE = 'ADD_GROUP_PHOTO_FAILURE'

export const REQUEST_DELETE_GROUP_PHOTO = 'REQUEST_DELETE_GROUP_PHOTO'
export const DELETE_GROUP_PHOTO_SUCCESS = 'DELETE_GROUP_PHOTO_SUCCESS'
export const DELETE_GROUP_PHOTO_FAILURE = 'DELETE_GROUP_PHOTO_FAILURE'

export const REQUEST_UPDATE_GROUP_PHOTO = 'REQUEST_UPDATE_GROUP_PHOTO'
export const UPDATE_GROUP_PHOTO_SUCCESS = 'UPDATE_GROUP_PHOTO_SUCCESS'
export const UPDATE_GROUP_PHOTO_FAILURE = 'UPDATE_GROUP_PHOTO_FAILURE'


export const fetchGroupPhoto = () => {
  return ({
  
    [CALL_API]: {
      types: [FETCH_GROUP_PHOTO, FETCH_GROUP_PHOTO_SUCCESS, FETCH_GROUP_PHOTO_FAILURE],
      endpoint: '/api/groupPhoto/',
      options: {
        method: 'GET'
      }
    }
  })
}
  
  export const deleteGroupPhoto = (id) => ({
    [CALL_API]: {
      types: [REQUEST_DELETE_GROUP_PHOTO, DELETE_GROUP_PHOTO_SUCCESS, DELETE_GROUP_PHOTO_FAILURE],
      endpoint: `/api/groupPhoto/delete?id=${id}`,
      options: {
        method: 'DELETE'
      }
    }
  })
  
  export const createGroupPhoto = (payload) => {
    
    const formData = new FormData()
  Object.keys(payload).forEach(key => {
    formData.append(key, payload[key])
  })
    
    // const formData = new FormData();
    // for (let key in payload){
    //   formData.set(key, payload[key])
    // }
    
  //   const formData = new FormData();
  //   formData.set('groupphoto', file);
  // console.log("FormData ==> ", formData)
    return {
      [CALL_API]: {
        types: [REQUEST_ADD_GROUP_PHOTO, ADD_GROUP_PHOTO_SUCCESS, ADD_GROUP_PHOTO_FAILURE],
        endpoint: '/api/groupPhoto/create',
        options: {
          method: 'POST',
          body: formData
        }
      }
    }
  }

  export const updateGroupPhoto = (payload,id) =>{
    // console.log("payload H3ere ==>", payload)
    const formData = new FormData();
    for (let key in payload){
      formData.set(key, payload[key])
    }
    
    return {
      [CALL_API]: {
        types: [REQUEST_UPDATE_GROUP_PHOTO, UPDATE_GROUP_PHOTO_SUCCESS, UPDATE_GROUP_PHOTO_FAILURE],
        endpoint: `/api/groupPhoto/update/${id}`,
        options: {
          method: 'PUT',
          body: formData
        }
      }
    }
  }