import { CALL_API } from "../apiMiddleware";

export const FETCH_FRANCHISE = "FETCH_FRANCHISE";
export const FETCH_FRANCHISE_SUCCESS = "FETCH_FRANCHISE_SUCCESS";
export const FETCH_FRANCHISE_FAILURE = "FETCH_FRANCHISE_FAILURE";


export const fetchFranchise = ({ per_page = 10, current_page = 1 }) => ({
  [CALL_API]: {
    types: [FETCH_FRANCHISE, FETCH_FRANCHISE_SUCCESS, FETCH_FRANCHISE_FAILURE],
    endpoint: `/api/franchise/getFranchiseData?per_page=${per_page}&current_page=${current_page}`,
    options: {
      method: "GET",
    },
  },
});
