import { combineReducers } from 'redux'
import { toast } from 'react-toastify'

import * as ActionTypes from '../../actions/testimonials'

export const isAddingTestimonial = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_ADD_TESTIMONIAL:
      return true

    case ActionTypes.REQUEST_ADD_TESTIMONIAL_SUCCESS: {
      toast.success('Testimonial added successfuly!')
      return false
    }
    case ActionTypes.REQUEST_ADD_TESTIMONIAL_FAILURE: {
      toast.error('Something went wrong, please try later.')
      return false
    }

    default:
      return state
  }
}
export const testimonialAddedSuccessfuly = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_ADD_TESTIMONIAL_SUCCESS:
      return true

    case ActionTypes.REQUEST_ADD_TESTIMONIAL_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  isAddingTestimonial,
  testimonialAddedSuccessfuly
})