import { toast } from 'react-toastify';
import * as ActionTypes from '../../actions/groupphoto';

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: [],

  isDeletingGroupPhoto: false,
  groupphotoDeleted: false,

  isCreatingGroupPhoto: false,
  groupphotoCreated: false,

  isUploadingGroupPhoto: false,
  groupphotoUploadedSuccessfuly: false
}

export const groupphoto = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_GROUP_PHOTO:
      return Object.assign({}, state, { isLoading: true });

    case ActionTypes.FETCH_GROUP_PHOTO_SUCCESS:
      return Object.assign({}, state, { isLoading: false, isDataAvailable: action.payload.length > 0, data: action.payload });

    case ActionTypes.FETCH_GROUP_PHOTO_FAILURE:
      return Object.assign({}, state, initialState);

    case ActionTypes.REQUEST_DELETE_GROUP_PHOTO:
      return Object.assign({}, state, { isDeletingGroupPhoto: true });

    case ActionTypes.DELETE_GROUP_PHOTO_SUCCESS:
      return Object.assign({}, state, { isDeletingGroupPhoto: false, groupphotoDeleted: true });

    case ActionTypes.DELETE_GROUP_PHOTO_FAILURE:
      return Object.assign({}, state, { isDeletingGroupPhoto: false, groupphotoDeleted: false });

    case ActionTypes.REQUEST_ADD_GROUP_PHOTO:
      return Object.assign({}, state, { isCreatingGroupPhoto: true });

    case ActionTypes.ADD_GROUP_PHOTO_SUCCESS: {
      toast.success('New group photo added successfully!')
      return Object.assign({}, state, { isCreatingGroupPhoto: false, groupphotoCreated: true });
    }

    case ActionTypes.ADD_GROUP_PHOTO_FAILURE:{
      toast.error(`${action.payload}`)
      return Object.assign({}, state, { isCreatingGroupPhoto: false, groupphotoCreated: false });
    }


    case ActionTypes.REQUEST_UPDATE_GROUP_PHOTO:
      return Object.assign({}, state, { isUploadingGroupPhoto: true });

    case ActionTypes.UPDATE_GROUP_PHOTO_SUCCESS:{
      toast.success('Group Photo Updated Successfuly!')
      return Object.assign({}, state, { isUploadingGroupPhoto: false, groupphotoUploadedSuccessfuly: true });
    }
    case ActionTypes.UPDATE_GROUP_PHOTO_FAILURE: {
      toast.error('Could not update group photo :( Please try again')
      return Object.assign({}, state, { isUploadingGroupPhoto: false, groupphotoUploadedSuccessfuly: false })
    }

    default:
      return state;
  }
}
