// // import { CALL_API } from '../apiMiddleware';

// // export const FETCH_VALUATION_QUERIES = 'FETCH_VALUATION_QUERIES';
// // export const FETCH_VALUATION_QUERIES_SUCCESS = 'FETCH_VALUATION_QUERIES_SUCCESS';
// // export const FETCH_VALUATION_QUERIES_FAILURE = 'FETCH_VALUATION_QUERIES_FAILURE';
// // export const REQUEST_VALUATION_STATUS_CHANGE = 'REQUEST_VALUATION_STATUS_CHANGE';
// // export const REQUEST_VALUATION_STATUS_CHANGE_SUCCESS = 'REQUEST_VALUATION_STATUS_CHANGE_SUCCESS';
// // export const REQUEST_VALUATION_STATUS_CHANGE_FAILURE = 'REQUEST_VALUATION_STATUS_CHANGE_FAILURE';

// // export const SET_VALUATION_QUERIES_DATE_RANGE = 'SET_VALUATION_QUERIES_DATE_RANGE';
// // export const SET_VALUATION_QUERIES_ORDER = 'SET_VALUATION_QUERIES_ORDER';
// // export const SET_VALUATION_FOCUSED_INPUT = 'SET_VALUATION_FOCUSED_INPUT';


// // export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
// // export const SET_PER_PAGE = 'SET_PER_PAGE';
// // export const SET_FILTER_DATE_RANGE = 'SET_FILTER_DATE_RANGE';



// // export const setCurrentPage = (currentPage) => ({
// //   type: SET_CURRENT_PAGE,
// //   payload: currentPage
// // })

// // export const setPerPage = (perPage) => ({
// //   type: SET_PER_PAGE,
// //   payload: perPage
// // })

// // export const setinActiveFilter = ({ startDate, endDate }) => ({
// //   type: SET_FILTER_DATE_RANGE,
// //   payload: { startDate, endDate }
// // })



// // export const setValuationQueriesDateRange = ({ startDate, endDate }) => ({
// //   type: SET_VALUATION_QUERIES_DATE_RANGE,
// //   payload: { startDate, endDate }
// // })

// // export const setValuationQueriesOrder = (order) => ({
// //   type: SET_VALUATION_QUERIES_ORDER,
// //   payload: order
// // })

// // export const setValuationFilterDateFocus = (focused) => ({
// //   type: SET_VALUATION_FOCUSED_INPUT,
// //   payload: focused
// // })

// // export const fetchValuationQueries = ({ orderBy='DESC', startDate, endDate ,per_page, current_page}) => ({
// //   [CALL_API]: {
// //     types: [FETCH_VALUATION_QUERIES, FETCH_VALUATION_QUERIES_SUCCESS, FETCH_VALUATION_QUERIES_FAILURE],
// //     endpoint: `/api/valuation?per_page=1&current_page=${current_page}&order=${orderBy}&startDate=${startDate.unix()*1000}&endDate=${endDate.unix()*1000}`,
// //     options: {
// //       method: 'GET'
// //     }
// //   }
// // })

// // export const requestValuationStatusChange = (id, attributes) => ({
// //   [CALL_API]: {
// //     types: [REQUEST_VALUATION_STATUS_CHANGE, REQUEST_VALUATION_STATUS_CHANGE_SUCCESS, REQUEST_VALUATION_STATUS_CHANGE_FAILURE],
// //     endpoint: `/api/valuation/update?id=${id}`,
// //     options: {
// //       method: 'POST',
// //       headers: {
// //         'Content-Type': 'application/json'
// //       },
// //       body: JSON.stringify(attributes)
// //     },
// //     payload: { id, attributes }
// //   }
// // })

// import { CALL_API } from '../apiMiddleware';

// export const FETCH_INQUIRIES = 'FETCH_INQUIRIES';
// export const FETCH_INQUIRIES_SUCCESS = 'FETCH_INQUIRIES_SUCCESS';
// export const FETCH_INQUIRIES_FAILURE = 'FETCH_INQUIRIES_FAILURE';


// export const SET_HOTLISTING_QUERIES_DATE_RANGE = 'SET_VALUATION_QUERIES_DATE_RANGE';
// export const SET_HOTLISTING_QUERIES_ORDER = 'SET_HOTLISTING_QUERIES_ORDER';
// export const SET_HOTLISTING_FOCUSED_INPUT = 'SET_VALUATION_FOCUSED_INPUT';


// export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
// export const SET_PER_PAGE = 'SET_PER_PAGE';

// export const FETCH_HOTLISTING = 'FETCH_HOTLISTING';
// export const FETCH_HOTLISTING_SUCCESS = 'FETCH_HOTLISTING_SUCCESS';
// export const FETCH_HOTLISTING_FAILURE = 'FETCH_HOTLISTING_FAILURE'

// export const DELETE_HOTLISTING = 'DELETE_HOTLISTING';
// export const DELETE_HOTLISTING_SUCCESS = 'DELETE_HOTLISTING_SUCCESS';
// export const DELETE_HOTLISTING_FAILURE = 'DELETE_HOTLISTING_FAILURE';

// export const ACTIVATE_HOTLISTING  = 'ACTIVATE_HOTLISTING';
// export const ACTIVATE_HOTLISTING_SUCCESS  = 'ACTIVATE_HOTLISTING_SUCCESS';;
// export const ACTIVATE_HOTLISTING_FAILURE  = 'ACTIVATE_HOTLISTING_FAILURE';

// export const setCurrentPage = (currentPage) => ({
//   type: SET_CURRENT_PAGE,
//   payload: currentPage
// })

// export const setPerPage = (perPage) => ({
//   type: SET_PER_PAGE,
//   payload: perPage
// })




// export const setInquiryQueriesDateRange = ({ startDate, endDate }) => ({
//   type: SET_HOTLISTING_QUERIES_DATE_RANGE,
//   payload: { startDate, endDate }
// })

// export const setInquiryQueriesOrder = (order) => ({
//   type: SET_HOTLISTING_QUERIES_ORDER,
//   payload: order
// })

// export const setInquiryFilterDateFocus = (focused) => ({
//   type: SET_HOTLISTING_FOCUSED_INPUT,
//   payload: focused
// })

// export const gethotlisting = ({ 
//   orderBy='DESC',
//  startDate, endDate }) => ({
//   [CALL_API]: {
//     types: [FETCH_HOTLISTING, FETCH_HOTLISTING_SUCCESS, FETCH_HOTLISTING_FAILURE],
//     endpoint: `/api/hotListing?startDate=${startDate.unix()*1000}&endDate=${endDate.unix()*1000}`,
//     options: {
//       method: 'GET'
//     }
//   }
// })
// export const deletehotlisting = (id) => ({
//   [CALL_API]: {
//     types: [DELETE_HOTLISTING, DELETE_HOTLISTING_SUCCESS, DELETE_HOTLISTING_FAILURE],
//     endpoint: '/api/hotListing/delete',
//     options: {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({ id })
//     }
//   }
// })
// export const activatehotlisting = (id) => ({
//   [CALL_API]: {
//     types: [ACTIVATE_HOTLISTING, ACTIVATE_HOTLISTING_SUCCESS, ACTIVATE_HOTLISTING_FAILURE],
//     endpoint: '/api/hotListing/activate',
//     options: {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify( {id,status:1} )
//     }
//   }
// })

