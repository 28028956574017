import { combineReducers } from 'redux'
import { toast } from 'react-toastify'

import * as ActionTypes from '../../actions/testimonials'
import addTestimonial from './addTestimonial'



const data = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TESTIMONIALS_SUCCESS:
      return action.payload
    default:
      return state
  }
}
const isLoading = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TESTIMONIALS:
      return true

    case ActionTypes.FETCH_TESTIMONIALS_SUCCESS:
    case ActionTypes.FETCH_TESTIMONIALS_FAILURE:
      return false

    default:
      return state
  }
}
const isDataAvailable = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TESTIMONIALS_SUCCESS:
      return (action.payload.length > 0)

    case ActionTypes.FETCH_TESTIMONIALS_FAILURE:
      return false

    default:
      return state
  }
}

const isDeletingTestimonial = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_DELETE_TESTIMONIAL:
      return true


    case ActionTypes.REQUEST_DELETE_TESTIMONIAL_SUCCESS: {
      toast.success('Testimonial Deleted Successfuly')
      return false
    }

    case ActionTypes.REQUEST_DELETE_TESTIMONIAL_FAILURE: {
      toast.error('Something went wrong while deleting this testimonial, please try again later.')
      return false
    }

    default:
      return state
  }
}

export const testimonials = combineReducers({
  isLoading,
  isDataAvailable,
  data,
  addTestimonial,
  isDeletingTestimonial
})