import {
  FETCH_HOTLISTING,
  FETCH_HOTLISTING_SUCCESS,
  FETCH_HOTLISTING_FAILURE,
    // FETCH_INQUIRIES,
    // FETCH_INQUIRIES_FAILURE,
    // FETCH_INQUIRIES_SUCCESS,
    DELETE_HOTLISTING,
    DELETE_HOTLISTING_SUCCESS,
    DELETE_HOTLISTING_FAILURE,
    SET_HOTLISTING_FOCUSED_INPUT,
    SET_HOTLISTING_STATUS,
    SET_HOTLISTING_QUERIES_DATE_RANGE,
    SET_HOTLISTING_QUERIES_ORDER,
    DEACTIVATE_HOTLISTING,
    DEACTIVATE_HOTLISTING_SUCCESS,
    DEACTIVATE_HOTLISTING_FAILURE,
    // SET_CURRENT_PAGE,
    //  SET_PER_PAGE,
    ACTIVATE_HOTLISTING,
    ACTIVATE_HOTLISTING_SUCCESS,
    ACTIVATE_HOTLISTING_FAILURE,
  } from '../../actions/hotListing';
import { gethotlisting } from '../../actions/hotListing';
  import { combineReducers } from 'redux';
  import moment from 'moment';
  
  const initialState = {
    isLoading: false,
    isDataAvailable: false,
    // status:1,
    data: [],
    pagination: {
      per_page: null,
      current_page: null,
      total_pages: null,
      total_records: null,
    },
  }
  // debugger
  export const queries = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_HOTLISTING:
        return Object.assign({}, state, { isLoading: true });
  
      case FETCH_HOTLISTING_SUCCESS:
        console.log('action.payload.data', action.payload.pagination)
        return Object.assign({}, state, { isLoading: false, data: action.payload, isDataAvailable: action.payload.results.length > 0 
          ,pagination: action.payload.pagination });
      // case FETCH_HOTLISTING_INACTIVE:
      //   return Object.assign({}, state, { isLoading: false, data: action.payload.data, isDataAvailable: action.payload.length > 0 
      //     ,pagination: action.payload.pagination,status:0 }); 
      case FETCH_HOTLISTING_FAILURE:
        return Object.assign({}, state, initialState);
  
      default:
        return state;
    }
  }
  
  const filtersInitialState = {
    orderBy: 'updatedAt',
    order:'DESC',
    startDate: moment().startOf('day').subtract(30, 'days'),
    endDate: moment().endOf('day'),
    focusedInput: null,
    isDeletingHotListing: false,
    isDeactivatingHotListing: false,
    isActivatingHotListing: false,
    status: 1
  }
 
  const filters = (state = filtersInitialState, action) => {
    switch (action.type) {
  
      case SET_HOTLISTING_QUERIES_DATE_RANGE: {
        const { payload: { startDate, endDate } } = action;
        return Object.assign({}, state, { startDate, endDate });
      }
      case SET_HOTLISTING_QUERIES_ORDER:
        return Object.assign({}, state, { orderBy: action.payload,order: action.payload });
  
      case SET_HOTLISTING_FOCUSED_INPUT:
        return Object.assign({}, state, { focusedInput: action.payload });
      case SET_HOTLISTING_STATUS:
        return Object.assign({}, state, { status: action.payload });
      case DELETE_HOTLISTING:
        // gethotlisting(state.myStartDate, state.myEndDate)
        return Object.assign({}, state, { isDeletingHotListing: true });
  
      case DELETE_HOTLISTING_SUCCESS:
        
      case DELETE_HOTLISTING_FAILURE:
        return Object.assign({}, state, { isDeletingHotListing: false })
      case  DEACTIVATE_HOTLISTING:
        return Object.assign({}, state, { isDeactivatingHotListing: true });
      case DEACTIVATE_HOTLISTING_SUCCESS:
        // return Object.assign({}, state, { status: 0, isDeactivatingHotListing: true });//comment this to not update state or status
      case DEACTIVATE_HOTLISTING_FAILURE:
        return Object.assign({}, state, { isDeactivatingHotListing: false });
        case  ACTIVATE_HOTLISTING:
        return Object.assign({}, state, { isActivatingHotListing: true });
      case ACTIVATE_HOTLISTING_SUCCESS:
      case ACTIVATE_HOTLISTING_FAILURE:
        return Object.assign({}, state, { isActivatingHotListing: false });
      //   case SET_CURRENT_PAGE:
  
      // return Object.assign({}, state, { current_page: action.payload });

      //    case SET_PER_PAGE:
      // return Object.assign({}, state, { per_page: action.payload });

      default:
        return state;  
    } 
  }
  export const hotListing = combineReducers({
    queries,
    filters
  })