import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";

import { routes } from "../../config";
import "./Sidebar.scss";

class Sidebar extends Component {
  handleClickItemSidebar = (path) => {
    const { history } = this.props;
    history.push(path);
  };

  logout = () => {
    const { cookies } = this.props;
    cookies.remove("authorization");
    cookies.remove("auth-expiry");
  };

  render() {
    const {
      isOpen,
      location: { pathname },
    } = this.props;
    return (
      <div className={isOpen ? "sidebar-root" : "sidebar-root hidden"}>
        <div style={{ position: "relative", height: "100%" }}>
          {routes.map((item, index) => {
            const isRouteActive =
              pathname !== "/" && item.path.indexOf(pathname) !== -1;
            return (
              <div
                className={isRouteActive ? "route-item active" : "route-item"}
                key={item.id || index}
                onClick={() => {
                  this.handleClickItemSidebar(item.path);
                }}
              >
                {item.iconType !== "custom" ? (
                  <FontAwesomeIcon icon={item.icon} />
                ) : (
                  <img
                    src={item.icon}
                    alt="Play"
                    style={{
                      width: "10px",
                      height: "10px",
                      marginLeft: "20px",
                      marginRight: "18px",
                    }}
                  />
                )}
                <p>{item.label}</p>
              </div>
            );
          })}
          <div
            className={"route-item"}
            onClick={this.logout}
            style={{
              position: "absolute",
              width: "100%",
              bottom: 0,
              backgroundColor: "red",
            }}
          >
            <FontAwesomeIcon icon="sign-out-alt" />
            <p>Logout</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withCookies(Sidebar));

// #33444C
