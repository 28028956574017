import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import './Header.scss'

class Header extends Component {
  render() {
    const { toggleSideBar, history } = this.props;
    //https://s3.us-east-1.amazonaws.com/images.graana.rocks/agency21/images/original/logos/logo_footer/1536840897_png
      // https://res.cloudinary.com/agency21/image/upload/v1536840897/logos/logo_footer.png
    return (
      <div className="header-root">
        <div className="logo-container">
          <img
            src="/images/logo_footer.png"
            alt="n/a"
            onClick={() => { history.push('/') }}
          />
          <FontAwesomeIcon className="icon-toggle" icon="bars" onClick={toggleSideBar} />
        </div>
        <div className="header-text">
          <p>Admin Dashboard</p>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);