import { FETCH_GET_IN_TOUCH, FETCH_GET_IN_TOUCH_SUCCESS } from "../../actions";

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  isDeletingAgent: false,
  data: [],
  pagination: {
    per_page: null,
    current_page: null,
    total_pages: null,
    total_records: null,
  },
  isUploadingAgent: false,
  agentUploadedSuccessfuly: false,
};

export const getInTouch = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_IN_TOUCH:
      return Object.assign({}, state, { isLoading: true });

    case FETCH_GET_IN_TOUCH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isDataAvailable: action.payload.data.length > 0,
        data: action.payload.data,
        pagination: action.payload.pagination,
      });

    default:
      return state;
  }
};
