import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { agents } from './agents';
import { gallery } from './gallery';
import { auth } from './auth';
import { inquiries } from './inquiries';
import { covers } from './covers';
import { wanted } from './wanted';
import { groupphoto } from './groupphoto'
import { testimonials } from './testimonials'
import { projectVideos } from './projectVideos';
import { franchise } from "./franchise";
import { getInTouch } from "./getInTouch";
import { hotListing } from "./hotListing";
import { inactive }  from "./inactive";
import { listingDetail } from "./listingDetail";

import instantValuation from './instantValuation';

import { CREATE_AGENT_SUCCESS } from '../actions/agents';
import { CREATE_PROJECTVIDEOS_SUCCESS } from '../actions/projectVideos';
import { REQUEST_CREATE_COVER_SUCCESS } from '../actions/covers';


export default combineReducers({
  agents,
  gallery,
  covers,
  auth,
  inquiries,
  wanted,
  instantValuation,
  groupphoto,
  testimonials,
  projectVideos,
  franchise,
  getInTouch,
  hotListing,
  inactive,
  listingDetail,
  form: formReducer.plugin({
    addAgent: (state, action) => {
      switch (action.type) {
        case CREATE_AGENT_SUCCESS:
          return undefined;

        default:
          return state;
      }
    },
    addProjectVideo: (state, action) => {
      switch (action.type) {
        case CREATE_PROJECTVIDEOS_SUCCESS:
          return undefined;

        default:
          return state;
      }
    },
    addCover: (state, action) => {
      switch (action.type) {
        case REQUEST_CREATE_COVER_SUCCESS:
          return undefined;

        default:
          return state;
      }
    }
  })
})