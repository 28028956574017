import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import './BreadCrumbs.scss';

class BreadCrumbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          path: '/',
          label: 'Home'
        }
      ]
    }
  }

  componentDidMount() {
    const { location: { pathname } } = this.props;
    if (pathname !== '/') {
      const paths = pathname === '/' ? [""] : pathname.split('/')
      this.setState({
        items: paths.map((item, index) => ({ label: item === '' ? 'Home' : item, path: item === '' ? '/' : paths.slice(0, index + 1).join('/') }))
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const { location: { pathname } } = nextProps;
      const paths = pathname === '/' ? [""] : pathname.split('/');
      this.setState({
        items: paths.map((item, index) => ({ label: item === '' ? 'Home' : item, path: item === '' ? '/' : paths.slice(0, index + 1).join('/') }))
      })
    }
  }

  handleClickBreadcrumb = (path) => {
    const { history } = this.props;
    history.push(path);
  }

  render() {
    const { location: { pathname } } = this.props;
    const { items } = this.state;
    return (
      <Breadcrumb className="breadcrumbs-root">
        {
          items.map((item, i) => (
            <BreadcrumbItem key={i} active={item.path === pathname} onClick={() => { this.handleClickBreadcrumb(item.path) }}>
              {item.label.replace('-', ' ')}
            </BreadcrumbItem>
          ))
        }
      </Breadcrumb>
    );
  }
}

export default withRouter(BreadCrumbs);