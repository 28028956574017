import { PlayButtonIcon, ListSolid } from "../assets";

export const routes = [
  {
    id: 1,
    path: "/hotListing?per_page=5&current_page=1",
    label: "Hot Listings",
    icon: ListSolid,
    iconType: "custom",
  },
  {
    id: 2,
    path: "/advisors?per_page=5&current_page=1",
    label: "Advisors",
    icon: "user-tie",
  },
  {
    id: 3,
    path: "/gallery",
    label: "Gallery",
    icon: "images",
  },
  {
    id: 4,
    path: "/inquiries",
    label: "Inquiries",
    icon: "question-circle",
  },
  // {
  //   id: 4,
  //   path: '/covers',
  //   label: 'Covers',
  //   icon: 'images'
  // },
  {
    id: 5,
    path: "/wanted?per_page=5&current_page=1",
    label: "Wanted",
    icon: "bell",
  },
  // {
  //   id: 11,
  //   path: '/valuation',
  //   label: 'Instant Valuation',
  //   icon: 'search-dollar'
  // },
  {
    id: 6,
    path: "/testimonials",
    label: "Testimonials",
    icon: "comments",
  },
  {
    id: 7,
    path: "/group-photo",
    label: "Group Photo",
    icon: "images",
  },
  {
    id: 8,
    path: "/project-videos",
    label: "Project Videos",
    icon: PlayButtonIcon,
    iconType: "custom",
  },
  {
    id: 9,
    path: "/franchise?per_page=10&current_page=1",
    label: "Franchise",
    icon: ListSolid,
    iconType: "custom",
  },
  {
    id: 10,
    path: "/getInTouch?per_page=10&current_page=1",
    label: "Get In Touch",
    icon: "users",
  },
  // {
  //   id: 11,
  //   path: "/hotListing?per_page=5&current_page=1&status=0",
  //   label: "Hot Listings",
  //   icon: ListSolid,
  //   iconType: "custom",
  // },
];
