import { CALL_API } from '../apiMiddleware';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_FAILURE = 'REQUEST_LOGIN_FAILURE';

export const RESET_LOGIN = 'RESET_LOGIN';

export const requestLogin = (payload) => ({
  [CALL_API]: {
    types: [ REQUEST_LOGIN, REQUEST_LOGIN_SUCCESS, REQUEST_LOGIN_FAILURE ],
    endpoint: '/api/auth/system-user-login',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }
  }
})

export const resetLogin = () => ({
  type: RESET_LOGIN
})