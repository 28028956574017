import { toast } from 'react-toastify';
import * as ActionTypes from '../../actions/covers';

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: [],

  isDeletingCover: false,
  coverDeleted: false,

  isCreatingCover: false,
  coverCreated: false
}

export const covers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COVERS:
      return Object.assign({}, state, { isLoading: true });

    case ActionTypes.FETCH_COVERS_SUCCESS:
      return Object.assign({}, state, { isLoading: false, isDataAvailable: action.payload.length > 0, data: action.payload });

    case ActionTypes.FETCH_COVERS_FAILURE:
      return Object.assign({}, state, initialState);

    case ActionTypes.REQUEST_COVER_DELETE:
      return Object.assign({}, state, { isDeletingCover: true });

    case ActionTypes.COVER_DELETE_SUCCESS:
      return Object.assign({}, state, { isDeletingCover: false, coverDeleted: true });

    case ActionTypes.COVER_DELETE_FAILURE:
      return Object.assign({}, state, { isDeletingCover: false, coverDeleted: false });

    case ActionTypes.REQUEST_CREATE_COVER:
      return Object.assign({}, state, { isCreatingCover: true });

    case ActionTypes.REQUEST_CREATE_COVER_SUCCESS: {
      toast.success('New cover added successfully!')
      return Object.assign({}, state, { isCreatingCover: false, coverCreated: true });
    }

    case ActionTypes.REQUEST_CREATE_COVER_FAILURE:{
      toast.error(`${action.payload}`)
      return Object.assign({}, state, { isCreatingCover: false, coverCreated: false });
    }
    default:
      return state;
  }
}
