import { CALL_API } from '../apiMiddleware';

export const FETCH_VALUATION_QUERIES = 'FETCH_VALUATION_QUERIES';
export const FETCH_VALUATION_QUERIES_SUCCESS = 'FETCH_VALUATION_QUERIES_SUCCESS';
export const FETCH_VALUATION_QUERIES_FAILURE = 'FETCH_VALUATION_QUERIES_FAILURE';
export const REQUEST_VALUATION_STATUS_CHANGE = 'REQUEST_VALUATION_STATUS_CHANGE';
export const REQUEST_VALUATION_STATUS_CHANGE_SUCCESS = 'REQUEST_VALUATION_STATUS_CHANGE_SUCCESS';
export const REQUEST_VALUATION_STATUS_CHANGE_FAILURE = 'REQUEST_VALUATION_STATUS_CHANGE_FAILURE';

export const SET_VALUATION_QUERIES_DATE_RANGE = 'SET_VALUATION_QUERIES_DATE_RANGE';
export const SET_VALUATION_QUERIES_ORDER = 'SET_VALUATION_QUERIES_ORDER';
export const SET_VALUATION_FOCUSED_INPUT = 'SET_VALUATION_FOCUSED_INPUT';


export const setValuationQueriesDateRange = ({ startDate, endDate }) => ({
  type: SET_VALUATION_QUERIES_DATE_RANGE,
  payload: { startDate, endDate }
})

export const setValuationQueriesOrder = (order) => ({
  type: SET_VALUATION_QUERIES_ORDER,
  payload: order
})

export const setValuationFilterDateFocus = (focused) => ({
  type: SET_VALUATION_FOCUSED_INPUT,
  payload: focused
})

export const fetchValuationQueries = ({ orderBy='DESC', startDate, endDate }) => ({
  [CALL_API]: {
    types: [FETCH_VALUATION_QUERIES, FETCH_VALUATION_QUERIES_SUCCESS, FETCH_VALUATION_QUERIES_FAILURE],
    endpoint: `/api/valuation?order=${orderBy}&startDate=${startDate.unix()*1000}&endDate=${endDate.unix()*1000}`,
    options: {
      method: 'GET'
    }
  }
})

export const requestValuationStatusChange = (id, attributes) => ({
  [CALL_API]: {
    types: [REQUEST_VALUATION_STATUS_CHANGE, REQUEST_VALUATION_STATUS_CHANGE_SUCCESS, REQUEST_VALUATION_STATUS_CHANGE_FAILURE],
    endpoint: `/api/valuation/update?id=${id}`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(attributes)
    },
    payload: { id, attributes }
  }
})