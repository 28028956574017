// // import isEmpty from "lodash/isEmpty";
// // import { combineReducers } from "redux";
// // import moment from "moment";
// // import * as ActionTypes from "../../actions";

// // import {
// //   REQUEST_VALUATION_STATUS_CHANGE,
// //   REQUEST_VALUATION_STATUS_CHANGE_FAILURE,
// //   REQUEST_VALUATION_STATUS_CHANGE_SUCCESS,
// //   SET_VALUATION_FOCUSED_INPUT,
// //   SET_VALUATION_QUERIES_DATE_RANGE,
// //   SET_VALUATION_QUERIES_ORDER,
// //   SET_CURRENT_PAGE,
// //   SET_PER_PAGE
// // } from "../../actions/inactive";

// // const initialState = {
// //   isLoading: false,
// //   isDataAvailable: false,
// //   data: [],
// //   pagination: {
// //       per_page: null,
// //       current_page: null,
// //       total_pages: null,
// //       total_records: null,
// //   },
// // };


// // const queries = (state = initialState, action) => {
// //   switch (action.type) {
// //     case ActionTypes.FETCH_VALUATION_QUERIES:
// //       // return { ...state, isLoading: true };
// //       return Object.assign({}, state, { isLoading: true });
// //     case REQUEST_VALUATION_STATUS_CHANGE_SUCCESS:
// //       return {
// //         ...state,
// //         data: [
// //           ...state.data.filter((item) => item.id !== action.payload.id),
// //           action.payload,
// //         ].sort((a, b) =>
// //           action.initialPayload.attributes.orderBy === "ASC"
// //             ? moment(a.createdAt).unix() - moment(b.createdAt).unix()
// //             : moment(b.createdAt).unix() - moment(a.createdAt).unix(),
// //             // action.initialPayload.attributes.orderBy === "sort by"
// //             // ? ""
// //             // : ""
// //         ),
// //       };

// //     case REQUEST_VALUATION_STATUS_CHANGE_FAILURE:
// //       return { ...state, isLoading: false };

// //     case ActionTypes.FETCH_VALUATION_QUERIES_SUCCESS:
// //       return {
// //         ...state,
// //         isLoading: false,
// //         isDataAvailable: !isEmpty(action.payload),
// //         data: action.payload,
// //         // data: action.payload.data, 
// //         pagination: action.payload.pagination, 
// //       };
// //       // return Object.assign({}, state, { isLoading: false, isDataAvailable: action.payload.data.length > 0,
// //       //   data: action.payload.data, pagination: action.payload.pagination, });
// //     case ActionTypes.FETCH_VALUATION_QUERIES_FAILURE:
// //       return { ...state, ...initialState };

// //     default:
// //       return state;
// //   }
// // };

// // // const updateInitialState = {
// // //   isUpdating: false,
// // //   id: null,
// // //   status: null,
// // // };

// // // const queryUpdating = (state = updateInitialState, action) => {
// // //   switch (action.type) {
// // //     case REQUEST_VALUATION_STATUS_CHANGE:
// // //       return {
// // //         ...state,
// // //         isUpdating: true,
// // //         id: action.payload.id,
// // //         status: action.payload.attributes.status,
// // //       };

// // //     case REQUEST_VALUATION_STATUS_CHANGE_SUCCESS:
// // //     case REQUEST_VALUATION_STATUS_CHANGE_FAILURE:
// // //       return { ...state, isUpdating: false };

// // //     default:
// // //       return state;
// // //   }
// // // };

// // const filtersInitialState = {
// //   orderBy: "DESC",
// //   startDate: moment().startOf("day").subtract(30, "days"),
// //   endDate: moment().endOf("day"),
// //   focusedInput: null,
// //   current_page:1,
// //   per_page:5,
// //   focusedInput: null
// // };

// // const filters = (state = filtersInitialState, action) => {
// //   switch (action.type) {
// //     case SET_VALUATION_FOCUSED_INPUT:
// //       return { ...state, focusedInput: action.payload };

// //     case SET_VALUATION_QUERIES_ORDER:
// //       return { ...state, orderBy: action.payload };

// //     case SET_VALUATION_QUERIES_DATE_RANGE: {
// //       const {
// //         payload: { startDate, endDate },
// //       } = action;
// //       return { ...state, startDate, endDate };
// //     }

// //     case SET_CURRENT_PAGE:
// //       return Object.assign({}, state, { current_page: action.payload });

// //     case SET_PER_PAGE:
// //       return Object.assign({}, state, { per_page: action.payload });

// //     default:
// //       return state;
// //   }
// // };

// // // export default combineReducers({
// // //   queries,
// // //   // queryUpdating,
// // //   filters,
// // // });
// // export const inactive = combineReducers({
// //   queries,
// //   filters
// // })

// import {
//   FETCH_HOTLISTING,
//   FETCH_HOTLISTING_SUCCESS,
//   FETCH_HOTLISTING_FAILURE,
//     // FETCH_INQUIRIES,
//     // FETCH_INQUIRIES_FAILURE,
//     // FETCH_INQUIRIES_SUCCESS,
//     DELETE_HOTLISTING,
//     DELETE_HOTLISTING_SUCCESS,
//     DELETE_HOTLISTING_FAILURE,
//     SET_HOTLISTING_FOCUSED_INPUT,
//     SET_HOTLISTING_QUERIES_DATE_RANGE,
//     SET_HOTLISTING_QUERIES_ORDER,
//     ACTIVATE_HOTLISTING,
//     ACTIVATE_HOTLISTING_SUCCESS,
//     ACTIVATE_HOTLISTING_FAILURE,
//   } from '../../actions/inactive';
// import { gethotlisting } from '../../actions/hotListing';
//   import { combineReducers } from 'redux';
//   import moment from 'moment';
  
//   const initialState = {
//     isLoading: false,
//     isDataAvailable: false,
    
//     data: [],
//     pagination: {
//       per_page: null,
//       current_page: null,
//       total_pages: null,
//       total_records: null,
//     },
//   }
//   // debugger
//   export const queries = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_HOTLISTING:
//         return Object.assign({}, state, { isLoading: true });
  
//       case FETCH_HOTLISTING_SUCCESS:
//         return Object.assign({}, state, { isLoading: false, data: action.payload.data, isDataAvailable: action.payload.length > 0 
//           , pagination: action.payload.pagination, });
  
//       case FETCH_HOTLISTING_FAILURE:
//         return Object.assign({}, state, initialState);
  
//       default:
//         return state;
//     }
//   }
  
//   const filtersInitialState = {
//     orderBy: 'ASC',
//     startDate: moment().startOf('day').subtract(30, 'days'),
//     endDate: moment().endOf('day'),
//     focusedInput: null,
//     isDeletingHotListing: false,
//     isactivatingHotListing: false,
//   }
 

//   const filters = (state = filtersInitialState, action) => {
//     switch (action.type) {
  
//       case SET_HOTLISTING_QUERIES_DATE_RANGE: {
//         const { payload: { startDate, endDate } } = action;
//         return Object.assign({}, state, { startDate, endDate });
//       }
//       case SET_HOTLISTING_QUERIES_ORDER:
//         return Object.assign({}, state, { orderBy: action.payload });
  
//       case SET_HOTLISTING_FOCUSED_INPUT:
//         return Object.assign({}, state, { focusedInput: action.payload });

//       case DELETE_HOTLISTING:
//         // gethotlisting(state.myStartDate, state.myEndDate)
//         return Object.assign({}, state, { isDeletingHotListing: true });
  
//       case DELETE_HOTLISTING_SUCCESS:
        
//       case DELETE_HOTLISTING_FAILURE:
//         return Object.assign({}, state, { isDeletingHotListing: false })
//       case  ACTIVATE_HOTLISTING:
//         return Object.assign({}, state, { isactivatingHotListing: true });
//       case ACTIVATE_HOTLISTING_SUCCESS:
//       case ACTIVATE_HOTLISTING_FAILURE:
//         return Object.assign({}, state, { isactivatingHotListing: false });

//       default:
//         return state;  
//     } 
//   }
//   export const inactive = combineReducers({
//     queries,
//     filters
//   })