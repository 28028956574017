import { CALL_API } from '../apiMiddleware';

export const FETCH_AGENTS = 'FETCH_AGENTS';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_FAILURE = 'FETCH_AGENTS_FAILURE';

export const DELETE_AGENT = 'DELETE_AGENT';
export const DELETE_AGENT_SUCCESS = 'DELETE_AGENT_SUCCESS';
export const DELETE_AGENT_FAILURE = 'DELETE_AGENT_FAILURE';

export const REQUEST_CREATE_AGENT = 'CREATE_AGENT';
export const CREATE_AGENT_SUCCESS = 'CREATE_AGENT_SUCCESS';
export const CREATE_AGENT_FAILURE = 'CREATE_AGENT_FAILURE';

export const REQUEST_UPDATE_AGENT = 'UPDATE_AGENT';
export const UPDATE_AGENT_SUCCESS = 'UPDATE_AGENT_SUCCESS';
export const UPDATE_AGENT_FAILURE = 'UPDATE_AGENT_FAILURE';

export const fetchAgents = ({ per_page=5, current_page=1 }) => ({
  [CALL_API]: {
    types: [FETCH_AGENTS, FETCH_AGENTS_SUCCESS, FETCH_AGENTS_FAILURE],
    endpoint: `/api/agents?per_page=${per_page}&current_page=${current_page}`,
    options: {
      method: 'GET'
    }
  }
})

export const deleteAgent = (id) => ({
  [CALL_API]: {
    types: [DELETE_AGENT, DELETE_AGENT_SUCCESS, DELETE_AGENT_FAILURE],
    endpoint: '/api/agents/delete',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id })
    }
  }
})

export const createAgent = (payload) => {
  const formData = new FormData();
  for (let key in payload){
    formData.set(key, payload[key])
  }
  
  return {
    [CALL_API]: {
      types: [REQUEST_CREATE_AGENT, CREATE_AGENT_SUCCESS, CREATE_AGENT_FAILURE],
      endpoint: `/api/agents/create`,
      options: {
        method: 'POST',
        body: formData
      }
    }
  }
}



export const updateAgents = (payload) =>{
  const formData = new FormData();
  for (let key in payload){
    formData.set(key, payload[key])
  }
  
  return {
    [CALL_API]: {
      types: [REQUEST_UPDATE_AGENT, UPDATE_AGENT_SUCCESS, UPDATE_AGENT_FAILURE],
      endpoint: `/api/agents/update/${payload.id}`,
      options: {
        method: 'PUT',
        body: formData
      }
    }
  }
}

