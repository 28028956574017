import { toast } from 'react-toastify';

import {
  FETCH_AGENTS,
  FETCH_AGENTS_SUCCESS,
  FETCH_AGENTS_FAILURE,
  DELETE_AGENT,
  DELETE_AGENT_FAILURE,
  DELETE_AGENT_SUCCESS,
  REQUEST_CREATE_AGENT,
  CREATE_AGENT_FAILURE,
  CREATE_AGENT_SUCCESS,
  REQUEST_UPDATE_AGENT,
  UPDATE_AGENT_FAILURE,
  UPDATE_AGENT_SUCCESS
} from '../../actions/agents';

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  isDeletingAgent: false,
  data: [],
  pagination: {
    per_page: null,
    current_page: null,
    total_pages: null,
    total_records: null,
  },
  isUploadingAgent: false,
  agentUploadedSuccessfuly: false,
};



export const agents = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AGENTS:
      return Object.assign({}, state, { isLoading: true });

    case FETCH_AGENTS_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isLoading: false,
          isDataAvailable: action.payload.data.length > 0,
          data: action.payload.data,
          pagination: action.payload.pagination
        }
      );

    case DELETE_AGENT:
      return Object.assign({}, state, { isDeletingAgent: true });

    case DELETE_AGENT_SUCCESS:
    case DELETE_AGENT_FAILURE:
      return Object.assign({}, state, { isDeletingAgent: false })

    case FETCH_AGENTS_FAILURE:
      return Object.assign({}, state, { isLoading: false, isDataAvailable: false });

    case REQUEST_CREATE_AGENT:
      return Object.assign({}, state, { isUploadingAgent: true, agentUploadedSuccessfuly: false });

    case CREATE_AGENT_SUCCESS:{
      toast.success('Agent Created Successfuly!')
      return Object.assign({}, state, { isUploadingAgent: false, agentUploadedSuccessfuly: true });
    }
    case CREATE_AGENT_FAILURE: {
      toast.error('Could not upload agent :( Please try again')
      return Object.assign({}, state, { isUploadingAgent: false, agentUploadedSuccessfuly: false })
    }

    // case REQUEST_CREATE_AGENT:
    //   return Object.assign({}, state, { isUploadingAgent: true, agentUploadedSuccessfuly: false });

    // case CREATE_AGENT_SUCCESS:{
    //   toast.success('Agent Created Successfuly!')
    //   return Object.assign({}, state, { isUploadingAgent: false, agentUploadedSuccessfuly: true });
    // }
    // case CREATE_AGENT_FAILURE: {
    //   toast.error('Could not upload agent :( Please try again')
    //   return Object.assign({}, state, { isUploadingAgent: false, agentUploadedSuccessfuly: false })
    // }

    case REQUEST_UPDATE_AGENT:
      return Object.assign({}, state, { isUploadingAgent: true, agentUploadedSuccessfuly: false });

    case UPDATE_AGENT_SUCCESS:{
      toast.success('Agent Created Successfuly!')
      return Object.assign({}, state, { isUploadingAgent: false, agentUploadedSuccessfuly: true });
    }
    case UPDATE_AGENT_FAILURE: {
      toast.error('Could not update agent :( Please try again')
      return Object.assign({}, state, { isUploadingAgent: false, agentUploadedSuccessfuly: false })
    }


    default:
      return state;
  }
}