import { CALL_API } from "../apiMiddleware";

export const FETCH_GET_IN_TOUCH = "FETCH_GET_IN_TOUCH";
export const FETCH_GET_IN_TOUCH_SUCCESS = "FETCH_GET_IN_TOUCH_SUCCESS";
export const FETCH_GET_IN_TOUCH_FAILURE = "FETCH_GET_IN_TOUCH_FAILURE";

export const fetchGetInTouch = ({ per_page = 10, current_page = 1 }) => ({
  [CALL_API]: {
    types: [
      FETCH_GET_IN_TOUCH,
      FETCH_GET_IN_TOUCH_SUCCESS,
      FETCH_GET_IN_TOUCH_FAILURE,
    ],
    endpoint: `/api/getInTouch/getGetInTouch?per_page=${per_page}&current_page=${current_page}`,
    options: {
      method: "GET",
    },
  },
});
