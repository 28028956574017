import React, { Component } from 'react';

import BreadCrumbs from '../BreadCrumbs';
import './ContentHeader.scss';

class ContentHeader extends Component {
  render(){
    return(
      <div className="content-header-root">
        <BreadCrumbs />
      </div>
    );
  }
}

export default ContentHeader;