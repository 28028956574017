import { CALL_API } from '../apiMiddleware';

export const FETCH_COVERS = 'FETCH_COVERS';
export const FETCH_COVERS_SUCCESS = 'FETCH_COVERS_SUCCESS';
export const FETCH_COVERS_FAILURE = 'FETCH_COVERS_FAILURE';

export const REQUEST_COVER_DELETE = 'REQUEST_COVER_DELETE';
export const COVER_DELETE_SUCCESS = 'COVER_DELETE_SUCCESS';
export const COVER_DELETE_FAILURE = 'COVER_DELETE_FAILURE'; 

export const REQUEST_CREATE_COVER = 'REQUEST_CREATE_COVER';
export const REQUEST_CREATE_COVER_SUCCESS = 'REQUEST_CREATE_COVER_SUCCESS';
export const REQUEST_CREATE_COVER_FAILURE = 'REQUEST_CREATE_COVER_FAILURE';

export const fetchCovers = () => ({
  [CALL_API]: {
    types: [FETCH_COVERS, FETCH_COVERS_SUCCESS, FETCH_COVERS_FAILURE],
    endpoint: '/api/covers',
    options: {
      method: 'GET'
    }
  }
})

export const deleteCover = (id) => ({
  [CALL_API]: {
    types: [REQUEST_COVER_DELETE, COVER_DELETE_SUCCESS, COVER_DELETE_FAILURE],
    endpoint: `/api/covers?id=${id}`,
    options: {
      method: 'DELETE'
    }
  }
})

export const createCover = (file) => {
  const formData = new FormData();
  formData.set('coverpicture', file);

  return {
    [CALL_API]: {
      types: [REQUEST_CREATE_COVER, REQUEST_CREATE_COVER_SUCCESS, REQUEST_CREATE_COVER_FAILURE],
      endpoint: '/api/covers',
      options: {
        method: 'POST',
        body: formData
      }
    }
  }
}