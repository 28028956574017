import { toast } from 'react-toastify';

import {
  FETCH_EVENTS,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILURE,
  DELETE_GALLERY_EVENT,
  DELETE_GALLERY_EVENT_SUCCESS,
  DELETE_GALLERY_EVENT_FAILURE,
  ADD_GALLERY_EVENT,
  ADD_GALLERY_EVENT_SUCCESS,
  ADD_GALLERY_EVENT_FAILURE,
  FETCH_EVENT_DETAIL,
  FETCH_EVENT_DETAIL_SUCCESS,
  FETCH_EVENT_DETAIL_FAILURE

} from '../../actions/gallery';

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: [],
  isDeletingEvent: false,
  isUploadingEvent: false,
  eventUploadSuccessful: false
}

export const events = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENT_DETAIL:
    case FETCH_EVENTS:
      return Object.assign({}, state, { isLoading: true });

    case FETCH_EVENT_DETAIL_SUCCESS:
    case FETCH_EVENTS_SUCCESS:
      return Object.assign({}, state, { isLoading: false, isDataAvailable: action.payload.length > 0, data: action.payload });

    case FETCH_EVENT_DETAIL_FAILURE:
    case FETCH_EVENTS_FAILURE:
      return Object.assign({}, state, initialState);

    case DELETE_GALLERY_EVENT:
      return Object.assign({}, state, { isDeletingEvent: true });

    case DELETE_GALLERY_EVENT_SUCCESS:
    case DELETE_GALLERY_EVENT_FAILURE:
      return Object.assign({}, state, { isDeletingEvent: false });

    case ADD_GALLERY_EVENT:
      return Object.assign({}, state, { isUploadingEvent: true, eventUploadSuccessful: false });

    case ADD_GALLERY_EVENT_FAILURE: {
      toast.error("Could not upload event :( please try again!");
      return Object.assign({}, state, { isUploadingEvent: false, eventUploadSuccessful: false });
    }

    case ADD_GALLERY_EVENT_SUCCESS: {
      toast.success("Event uploaded successfuly!");
      return Object.assign({}, state, { isUploadingEvent: false, eventUploadSuccessful: true });
    }

    default:
      return state;
  }
}