import isEmpty from 'lodash/isEmpty';

import { CALL_API } from '../apiMiddleware';

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILUR';

export const ADD_GALLERY_EVENT = 'ADD_GALLERY_EVENT';
export const ADD_GALLERY_EVENT_SUCCESS = 'ADD_GALLERY_EVENT_SUCCESS';
export const ADD_GALLERY_EVENT_FAILURE = 'ADD_GALLERY_EVENT_FAILURE';

export const DELETE_GALLERY_EVENT = 'DELETE_GALLERY_EVENT';
export const DELETE_GALLERY_EVENT_SUCCESS = 'DELETE_GALLERY_EVENT_SUCCESS';
export const DELETE_GALLERY_EVENT_FAILURE = 'DELETE_GALLERY_EVENT_FAILURE';

export const FETCH_EVENT_DETAIL = 'FETCH_EVENT_DETAIL';
export const FETCH_EVENT_DETAIL_SUCCESS = 'FETCH_EVENT_DETAIL_SUCCESS';
export const FETCH_EVENT_DETAIL_FAILURE = 'FETCH_EVENT_DETAIL_FAILURE';


export const addGalleryEvent = ({ files, ...rest }) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  })
  for (let key in rest) {
    if (rest[key]) {
      if(typeof(rest[key] === 'object')) {
        if(!isEmpty(rest[key])){
          formData.set(key, rest[key])
        }
      } else {
      formData.set(key, rest[key])
      }
    }
  }
  formData.set('date', rest['date_held_on']);
  formData.set('date_held_on', rest['date_held_on']);
  return {
    [CALL_API]: {
      types: [ADD_GALLERY_EVENT, ADD_GALLERY_EVENT_SUCCESS, ADD_GALLERY_EVENT_FAILURE],
      endpoint: '/api/events/event-upload',
      options: {
        method: 'POST',
        body: formData,
      }
    }
  }
}





export const editGalleryEvent = ({ files, ...rest }) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  })
  //console.log('rest',rest);
  for (let key in rest) {
    if (rest[key]) {
      if(typeof(rest[key] === 'object')) {
        if(!isEmpty(rest[key])){
          formData.set(key, rest[key])
        }
      } else {
      formData.set(key, rest[key])
      }
    }
  }
  formData.set('date', rest['date_held_on']);
  formData.set('date_held_on', rest['date_held_on']);

  return {
    [CALL_API]: {
      types: [ADD_GALLERY_EVENT, ADD_GALLERY_EVENT_SUCCESS, ADD_GALLERY_EVENT_FAILURE],
      endpoint: `/api/events/event-edit/${rest['id']}`,
      options: {
        method: 'POST',
        body: formData,
      }
    }
  }
}





// export const editGalleryEvent = ({...rest }) => {
//   const formData = new FormData();
//   for (let key in rest) {
//     if (rest[key]) {
//       if(typeof(rest[key] === 'object')) {
//         if(!isEmpty(rest[key])){
//           formData.set(key, rest[key])
//         }
//       } else {
//       formData.set(key, rest[key])
//       }
//     }
//   }

//   return {
//     [CALL_API]: {
//       types: [ADD_GALLERY_EVENT, ADD_GALLERY_EVENT_SUCCESS, ADD_GALLERY_EVENT_FAILURE],
//       endpoint: `/api/events/event-edit/${rest['id']}`,
//       options: {
//         method: 'POST',
//         body: formData,
//       }
//     }
//   }
// }











export const deleteGalleryEvent = (id) => {
  console.log("EVENT TO DELETE: ", id)
  return {
    [CALL_API]: {
      types: [DELETE_GALLERY_EVENT, DELETE_GALLERY_EVENT_SUCCESS, DELETE_GALLERY_EVENT_FAILURE],
      endpoint: `/api/events/delete`,
      options: {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id })
      }
    }
  }
}

export const deleteExistingImageEvent = (id,mediaId) => {
  console.log("EVENT TO DELETE: ", id, mediaId)
  return {
    [CALL_API]: {
      types: [DELETE_GALLERY_EVENT, DELETE_GALLERY_EVENT_SUCCESS, DELETE_GALLERY_EVENT_FAILURE],
      endpoint: `/api/events/deleteMedia`,
      options: {
        method: 'Delete',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id, mediaId })
      }
    }
  }
}

export const fetchEventDetail = (eventId) => ({

  [CALL_API]: {
    types: [FETCH_EVENT_DETAIL, FETCH_EVENT_DETAIL_SUCCESS, FETCH_EVENT_DETAIL_FAILURE],
    endpoint: `/api/events/${eventId}`,
    options: {
      method: 'GET'
    }
  }
});


export const fetchEvents = () => ({
  [CALL_API]: {
    types: [FETCH_EVENTS, FETCH_EVENTS_SUCCESS, FETCH_EVENTS_FAILURE],
    endpoint: '/api/events',
    options: {
      method: 'GET'
    }
  }
})