import {
  FETCH_INQUIRIES,
  FETCH_INQUIRIES_FAILURE,
  FETCH_INQUIRIES_SUCCESS,
  SET_INQUIRY_FOCUSED_INPUT,
  SET_INQUIRY_QUERIES_DATE_RANGE,
  SET_INQUIRY_QUERIES_ORDER,
} from '../../actions/inquiries';

import { combineReducers } from 'redux';
import moment from 'moment';

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: []
}

export const queries = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INQUIRIES:
      return Object.assign({}, state, { isLoading: true });

    case FETCH_INQUIRIES_SUCCESS:
      return Object.assign({}, state, { isLoading: false, data: action.payload, isDataAvailable: action.payload.length > 0 });

    case FETCH_INQUIRIES_FAILURE:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
}

const filtersInitialState = {
  orderBy: 'DESC',
  startDate: moment().startOf('year').subtract(10, 'years'),
  endDate: moment().endOf('day'),
  focusedInput: null
}

const filters = (state = filtersInitialState, action) => {
  switch (action.type) {

    case SET_INQUIRY_QUERIES_DATE_RANGE: {
      const { payload: { startDate, endDate } } = action;
      return Object.assign({}, state, { startDate, endDate });
    }

    case SET_INQUIRY_QUERIES_ORDER:
      return Object.assign({}, state, { orderBy: action.payload });

    case SET_INQUIRY_FOCUSED_INPUT:
      return Object.assign({}, state, { focusedInput: action.payload });


    default:
      return state;
  }
}

export const inquiries = combineReducers({
  queries,
  filters
})