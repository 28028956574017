import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Layout.scss';

import Header from '../Header';
import ContentHeader from '../ContentHeader';
import Sidebar from '../Sidebar';



class Layout extends Component {

  state = {
    isSideBarOpen: true,
  }

  toggleSideBar = () => {
    this.setState({
      isSideBarOpen: !this.state.isSideBarOpen
    })
  }

  render() {
    const { location: { pathname } } = this.props;
    return (
      <React.Fragment>
        {pathname === '/login' ? this.props.children :
          <div className="layout-root">
            <Header
              toggleSideBar={this.toggleSideBar}
            />
            <div className="content-root">
              <Sidebar isOpen={this.state.isSideBarOpen} />
              <div className={this.state.isSideBarOpen ? "content" : "content no-left-margin"}>
                <ContentHeader />
                <div className="content-route">
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);