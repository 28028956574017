import fetch from "isomorphic-unfetch";

export const CALL_API = Symbol("CALL_API");

const defaultApiSource =
  process.env.REACT_APP_ENV === "local"
    ? "https://xerox.agency21.com.pk"
    : "https://xerox.agency21.com.pk";

export const apiMiddleware = (store) => (next) => (action) => {
  if (action[CALL_API]) {
    const apiSource = action[CALL_API].apiSource || defaultApiSource;
    // defaultApiSource;
    const [FETCH, SUCCESS, FAILURE] = action[CALL_API].types;
    store.dispatch({ type: FETCH, payload: action[CALL_API].payload || {} });

    const api = apiSource + action[CALL_API].endpoint;

    fetch(api, action[CALL_API].options)
      .then((resp) => {
        if (resp.status >= 400) {
          switch (resp.status) {
            case 401:
              throw new Error("Auth token is not valid (unauthorized)");

            case 403:
              throw new Error("Auth token is not supplied (forbidden)");

            default:
              throw new Error("Bad response from server..");
          }
        }
        return resp.json();
      })
      .then((data) => {
        store.dispatch({
          type: SUCCESS,
          payload: data,
          initialPayload: action[CALL_API].payload || {},
        });
      })
      .catch((err) => {
        store.dispatch({ type: FAILURE, payload: err });
      });
  } else {
    next(action);
  }
};
