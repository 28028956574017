import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_FAILURE,
  REQUEST_LOGIN_SUCCESS,
  RESET_LOGIN
} from '../../actions/auth';

const initialState = {
  isLoggingIn: false,
  authSuccessful: false,
  token: ''
}

export const login = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOGIN:
      return Object.assign({}, state, { isLoggingIn: true })

    case REQUEST_LOGIN_SUCCESS: {
      const { success, token } = action.payload
      return Object.assign({}, state, { isLoggingIn: false, authSuccessful: success, token })
    }
    case REQUEST_LOGIN_FAILURE:
      return Object.assign({}, state, { isLoggingIn: false })

    case RESET_LOGIN:
      return Object.assign({}, state, initialState)

    default:
      return state
  }
}