import { CALL_API } from '../apiMiddleware';

export const FETCH_INQUIRIES = 'FETCH_INQUIRIES';
export const FETCH_INQUIRIES_SUCCESS = 'FETCH_INQUIRIES_SUCCESS';
export const FETCH_INQUIRIES_FAILURE = 'FETCH_INQUIRIES_FAILURE';


export const SET_INQUIRY_QUERIES_DATE_RANGE = 'SET_VALUATION_QUERIES_DATE_RANGE';
export const SET_INQUIRY_QUERIES_ORDER = 'SET_INQUIRY_QUERIES_ORDER';
export const SET_INQUIRY_FOCUSED_INPUT = 'SET_VALUATION_FOCUSED_INPUT';


export const setInquiryQueriesDateRange = ({ startDate, endDate }) => ({
  type: SET_INQUIRY_QUERIES_DATE_RANGE,
  payload: { startDate, endDate }
})

export const setInquiryQueriesOrder = (order) => ({
  type: SET_INQUIRY_QUERIES_ORDER,
  payload: order
})

export const setInquiryFilterDateFocus = (focused) => ({
  type: SET_INQUIRY_FOCUSED_INPUT,
  payload: focused
})

export const fetchInquiries = ({ orderBy='DESC', startDate, endDate }) => ({
  [CALL_API]: {
    types: [FETCH_INQUIRIES, FETCH_INQUIRIES_SUCCESS, FETCH_INQUIRIES_FAILURE],
    endpoint: `/api/inquiry?order=${orderBy}&startDate=${startDate.unix()*1000}&endDate=${endDate.unix()*1000}`,
    options: {
      method: 'GET'
    }
  }
})