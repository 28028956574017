import {
  FETCH_FRANCHISE,
  FETCH_FRANCHISE_SUCCESS,
} from "../../actions/franchise";

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  isDeletingAgent: false,
  data: [],
  pagination: {
    per_page: null,
    current_page: null,
    total_pages: null,
    total_records: null,
  },
  isUploadingAgent: false,
  agentUploadedSuccessfuly: false,
};

export const franchise = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FRANCHISE:
      return Object.assign({}, state, { isLoading: true });

    case FETCH_FRANCHISE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isDataAvailable: action.payload.data.length > 0,
        data: action.payload.data,
        pagination: action.payload.pagination,
      });

    default:
      return state;
  }
};
